<template>
  <div class="checkstand">
    <navbarTools :leftArrow="true" :fixed="true"></navbarTools>
    <div class="bgc-F7F7F7 jd-checkstand-wrap">
      <div class="pt10">
        <div class="bgc-fff pl15 pr15 pb20">
          <div class="flex justify-space-between line-height-55 fs16 c-999">
            <div>订单金额</div>
            <div class="c-FE6507">{{ orderDetail?.orderSettlementAmount }}工钱币</div>
          </div>
          <div class="jd-border-top pt19">
            <div class="fs16 c-999 line-height-16">支付方式</div>
            <div class="flex justify-space-between line-height-16 fs16 c-333 mt20">
              <div>工钱币账户（{{ coinAccount?.availableCount }}工钱币）</div>
              <div class="flex">
                <van-radio-group v-model="checked">
                  <van-radio name="2" checked-color="#FE6507"></van-radio>
                </van-radio-group>
              </div>
            </div>
          </div>
        </div>
        <div
          class="ml15 mr15 mt70 text-center bgc-FE6507 radius3 line-height-40 c-fff fs16 font-weight"
          @click="sureFn"
          :class="{
            unactive:
              Number(orderDetail?.orderSettlementAmount) >
              Number(coinAccount?.availableCount),
          }"
        >
          {{
            Number(orderDetail?.orderSettlementAmount) >
            Number(coinAccount?.availableCount)
              ? "工钱币不足"
              : "确定支付"
          }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import navbarTools from "@/components/navbarTools";
import { defineComponent, toRefs, reactive, getCurrentInstance, ref } from "vue";
import { coinAccount } from "@/api/coin";
import { orderDetail, orderPay } from "@/api/shop";
import { useRoute, useRouter } from "vue-router";
import { Toast } from "vant";
export default defineComponent({
  components: {
    navbarTools,
  },
  setup() {
    const $route = useRoute();
    const $router = useRouter();
    const state = reactive({
      checked: "2",
      coinAccount: null,
      orderDetail: null,
      payType: 1,
    });
    const coinAccountFn = async () => {
      let rs = await coinAccount({
        tenantId: localStorage.getItem("tenantId"),
        tenantUserId: localStorage.getItem("tenantUserId"),
      });
      if (rs.code === 0) {
        state.coinAccount = rs.data;
      }
    };
    const orderDetailFn = async () => {
      let rs = await orderDetail({ orderSn: $route.query.orderSn });
      if (rs.code === 0) {
        state.orderDetail = rs.data;
      }
    };
    const sureFn = async () => {
      const payLoading = Toast.loading({
        message: "支付中...",
        forbidClick: true,
        overlay: true,
        overlayClass: "loadingOverlay",
      });
      if (
        Number(state.orderDetail?.orderSettlementAmount) <=
        Number(state.coinAccount?.availableCount)
      ) {
        let rs = await orderPay({
          orderSn: $route.query.orderSn,
          payType: state.payType,
        });
        if (rs.code === 0) {
          $router.push({
            path: "/jdshop/paysuccess",
            query: {
              goodsChannel: $route.query.goodsChannel,
            },
          });
        }
      }
      payLoading.clear();
    };
    orderDetailFn();
    coinAccountFn();

    return {
      sureFn,
      ...toRefs(state),
    };
  },
});
</script>
<style lang="scss" scoped>
.checkstand {
  ::v-deep(.navbar) {
    width: 375px;
    .van-nav-bar {
      .van-nav-bar__left .van-icon {
        font-size: 24px;
        color: #000;
      }
      .van-nav-bar__title {
        font-size: 18px;
        font-weight: 600;
      }
      .van-nav-bar__right .van-nav-bar__text {
        color: #333;
      }
    }
  }
  .jd-checkstand-wrap {
    position: relative;
    top: 46px;
    .unactive {
      background: rgba(0, 0, 0, 0.15);
    }
  }
}
</style>
